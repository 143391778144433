import Login from "../components/pages/login/LoginPage";
//import PreEnrollmentInvited from "../components/pages/landingRoutes/PreEnrollmentInvitedPage";
import PreEnrollment from "../components/pages/landingRoutes/PreEnrollmentPage";

const routes = [
    {
        path: "/",
        element: <Login />
    },
    {
        path: "/login",
        element: <Login />
    },
    {
        path: "/login/:inviteID",
        element: <Login />
    },
    /*{
        path: "/pre-enrollment-invited",
        element: <PreEnrollmentInvited />
    },
    {
        path: "/pre-enrollment-invited/:inviteID",
        element: <PreEnrollmentInvited />
    },*/
    {
        path: "/pre-enrollment",
        element: <PreEnrollment />
    }
];

export default routes;
