import AccountPageDetails from "../components/pages/account/AccountPageDetails";
import AccountUsersPage from "../components/pages/account/AccountUsersPage";

const routes = [
    {
        path: "/account",
        element: <AccountPageDetails />
    },
    {
        path: "/account/users",
        element: <AccountUsersPage />
    }
];

export default routes;
