import React from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";
import Eye from "@untitled-ui/icons-react/build/esm/Eye";
import Plus from "@untitled-ui/icons-react/build/esm/Plus";
import Mail from "@untitled-ui/icons-react/build/esm/Mail05";
import Unknown from "@untitled-ui/icons-react/build/esm/FileQuestion01";
//import FileInvoiceSolid from "../../../assets/icons/FileInvoiceSolid";
//import CreditCardCheckIcon from "../../../assets/icons/CreditCardCheckIcon";
import { ReactComponent as FileInvoiceSolid } from "../../../assets/icons/file-invoice-solid.svg";
import { ReactComponent as CreditCardCheckIcon } from "../../../assets/icons/credit-card-check.svg";
import Microscope from "@untitled-ui/icons-react/build/esm/Microscope";
import PencilLine from "@untitled-ui/icons-react/build/esm/PencilLine";
import ChevronLeft from "@untitled-ui/icons-react/build/esm/ChevronLeft";

/* #region  SCOPED CSS */
const StyledPage = styled.div`
    .controlbar {
        background: var(--backgrounds-lines-n-400);
        box-sizing: border-box;
        display: flex;
        flex-flow: row nowrap;
        left: 0;
        max-width: 100%;
        padding: 0 24px;
        //position: fixed;
        right: 0;
        top: 70px;
        z-index: 1;
    }

    /* #region LEFT SIDE */
    .product-search.left-side {
        align-items: center;
        display: flex;
        flex-basis: 100%;
        height: 62px;
        justify-content: flex-start;
        padding-top: 5px;
        text-align: center;
    }

    .product-search.left-side .btn.btn-success {
        align-items: center;
        display: flex;
        justify-content: center;
        margin: 0;
    }

    .product-search.left-side .btn.btn-success i {
        font-size: 17px;
    }

    .controlbar .btn.btn-success,
    .main-header .btn.btn-success {
        align-items: center;
        color: var(--text-icons-gray-100);
        display: flex;
        font-family: Roboto;
        font-size: 12px;
        justify-content: center;
        margin: 0 5px;
        text-transform: uppercase;
    }

    .controlbar .btn.btn-success {
        background-color: var(--backgrounds-lines-n-600);
        border-color: var(--backgrounds-lines-n-600);
    }

    .product-search .btn.btn-success {
        align-items: center;
        display: flex;
        float: left;
        font-size: 16px;
        height: 37px;
        justify-content: center;
        line-height: 37px;
        padding: 0;
        width: 40px;
    }

    .button-not-active,
    .button-not-active .btn {
        color: var(--text-icons-gray-300) !important;
        cursor: not-allowed;
    }

    .button-not-active .search-predict-text {
        color: var(--text-icons-gray-300) !important;
    }

    .search-predict-text.text-center {
        float: none;
        justify-content: center;
        font-size: 8px;
    }

    .button-not-active .search-predict-text {
        color: var(--text-icons-gray-300) !important;
    }

    .search-predict-text.text-center {
        float: none;
        justify-content: center;
    }

    .search-predict-text {
        clear: both;
        color: var(--text-icons-gray-100);
        display: flex;
        float: left;
        font-size: 10px;
        text-align: center;
    }
    .search-predict-text {
        justify-content: flex-start;
    }

    /* #endregion */

    /* #region CENTER BUTTONS */
    .centerbtn {
        align-items: center;
        display: flex;
        flex-basis: 100%;
        height: 62px;
        justify-content: center;
        width: 100%;
    }

    .centerbtn .btn.btn-success {
        height: 35px;
        padding-left: 0;
        padding-right: 0;
        width: 120px;
        line-height: normal;
    }
    .controlbar .btn.btn-success {
        background-color: var(--backgrounds-lines-n-600);
        border-color: var(--backgrounds-lines-n-600);
    }
    .controlbar .btn.btn-success.active,
    .main-header .btn.btn-success.active {
        border-color: var(--App-Accent-Outline);
    }
    /* #endregion */

    /* #region RIGHT SIDE */
    .right-control-search {
        display: flex;
        flex-basis: 100%;
        justify-content: flex-end;
        width: 100%;
    }

    .product-search.right-side {
        float: right;
        padding-top: 5px;
        align-items: center;
        display: flex;
        justify-content: flex-end;
    }

    .right-control-search .product-search.right-side a {
        margin-left: 8px;
        display: inline-grid;
        place-content: center;
    }

    .controlbar .right-control-search .btn.btn-success {
        margin: 0;
    }
    .controlbar .btn.btn-success.active,
    .main-header .btn.btn-success.active {
        border-color: var(--App-Accent-Outline);
    }

    .controlbar .btn-icon i {
        font-size: 12px !important;
    }

    .right-control-search .search-predict-text {
        justify-content: center;
        width: 100%;
        font-size: 8px;
    }

    .search-predict-text {
        clear: both;
        color: var(--text-icons-gray-100);
        display: flex;
        float: left;
        font-size: 10px;
        text-align: center;
    }
    .search-predict-text {
        justify-content: flex-start;
    }

    .navbar-nav.ml-auto .nav-item.logoutbtn,
    .right-control-search .product-search.right-side:last-child a {
        margin-right: 0;
    }

    .controlbar .btn.btn-success:hover {
        border-color: var(--App-Accent-Outline);
    }
    /* #endregion */
`;
/* #endregion */

/**
 * ControlBar component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.className - The class name for the component.
 * @param {Array<Object>} props.leftBtns - The array of left buttons.
 * @param {boolean} props.leftBtns[].active - Indicates if the button is active.
 * @param {boolean} props.leftBtns[].disabled - Indicates if the button is disabled.
 * @param {string} props.leftBtns[].icon - The icon name for the button.
 * @param {string} props.leftBtns[].title - The title for the button.
 * @param {Function} props.leftBtns[].onClick - The click event handler for the button.
 * @param {Array<Object>} props.centerBtns - The array of center buttons.
 * @param {string} props.centerBtns[].to - The link destination for the button.
 * @param {boolean} props.centerBtns[].disabled - Indicates if the button is disabled.
 * @param {boolean} props.centerBtns[].saveIt - Indicates if the button is a save button.
 * @param {boolean} props.centerBtns[].active - Indicates if the button is active.
 * @param {string} props.centerBtns[].title - The title for the button.
 * @param {Array<Object>} props.rigthBtns - The array of right buttons.
 * @param {string} props.rigthBtns[].to - The link destination for the button.
 * @param {boolean} props.rigthBtns[].disabled - Indicates if the button is disabled.
 * @param {boolean} props.rigthBtns[].active - Indicates if the button is active.
 * @param {string} props.rigthBtns[].icon - The icon name for the button.
 * @param {string} props.rigthBtns[].title - The title for the button.
 * @param {Function} props.rigthBtns[].onClick - The click event handler for the button.
 * @returns {JSX.Element} The rendered ControlBar component.
 */
export default function ControlBar(props) {
    const navigate = useNavigate();

    return (
        <StyledPage className={`${props?.className || ""}`}>
            <div className="controlbar">
                <div className="product-search left-side">
                    {props?.leftBtns?.map(btn => {
                        const key = uuidv4();
                        return (
                            <span
                                key={key}
                                data-widget="search"
                                className={`${btn?.active ? "active" : ""} ${btn?.disabled ? "button-not-active" : ""}`}
                                to="#"
                                onClick={e => handleOnClick(e, btn?.onClick)}
                                role="button">
                                <span className="btn btn-success btn-icon">{getIcon(btn?.icon)}</span>
                                <span
                                    className={`search-predict-text text-center ${
                                        btn?.disabled ? " text-not-active" : ""
                                    }`}>
                                    {btn?.title}
                                </span>
                            </span>
                        );
                    })}
                </div>
                <div className="centerbtn">
                    {props?.centerBtns?.map(btn => {
                        const key = uuidv4();
                        return (
                            <span
                                key={key}
                                onClick={btn?.to && !btn?.disabled ? () => navigate(btn?.to) : undefined}
                                to={btn?.disabled ? "#" : btn?.to}
                                className={`btn btn-success ${btn?.saveIt ? "saveIt" : ""} ${
                                    btn?.active ? "active" : ""
                                } ${btn?.disabled ? "button-not-active" : ""}`}>
                                {btn?.title}
                            </span>
                        );
                    })}
                </div>
                <div className="right-control-search">
                    {props?.rigthBtns?.map(btn => {
                        const key = uuidv4();
                        return (
                            <div key={key} className="product-search right-side saveIt">
                                <Link
                                    data-widget="search"
                                    to={btn?.to}
                                    role="button"
                                    onClick={e => handleOnClick(e, btn)}>
                                    <span
                                        className={`btn btn-success btn-icon ${btn?.active ? "active" : ""} ${
                                            btn?.disabled ? "button-not-active" : ""
                                        }`}>
                                        {getIcon(btn?.icon)}
                                    </span>
                                    <span className={`search-predict-text ${btn?.disabled ? " text-not-active" : ""}`}>
                                        {btn?.title}
                                    </span>
                                </Link>
                            </div>
                        );
                    })}
                </div>
            </div>
        </StyledPage>
    );
}

function handleOnClick(e, btn) {
    try {
        if (btn?.disabled || !btn?.to) e.preventDefault();
    } catch (e) {}
    if (btn?.onClick) {
        btn?.onClick();
    }
}

function getIcon(iconName) {
    if (iconName.includes("fa-")) {
        return <i className={`fa-light ${iconName}`}></i>;
    }
    iconName = iconName.replace("fa-solid ", "").replace("fa-", "");

    switch (iconName.toLowerCase()) {
        case "eye":
            return <Eye height="16px" />;
        case "plus":
            return <Plus height="16px" />;
        case "file-invoice-solid":
            return <FileInvoiceSolid size="16" />;
        case "credit-card-check":
            return <CreditCardCheckIcon size="16" />;
        case "mail":
            return <Mail height="16" />;
        case "microscope":
            return <Microscope height="16px" />;
        case "pencil-line":
            return <PencilLine height="16px" />;
        case "chevron-left":
            return <ChevronLeft height="16px" />;

        //ADD NEW ICONS HERE

        default:
            console.error("Unknown untitled-ui icon: " + iconName);
            return <Unknown />;
    }
}
