import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

/* #region  SCOPED CSS */
const StyledPage = styled.div`
    & {
        width: 100%;
    }

    .BO-card-component {
        width: 100%;
        min-height: 100px;
        padding: 16px 0px 32px 0px;
        background: var(--backgrounds-lines-n-400);
        border-radius: 4px;
        margin-bottom: 24px;
    }

    .BO-card-header {
        padding: 0px 16px;
        color: var(--text-icons-gray-100);
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: uppercase;
        display: flex;
        gap: 8px;
    }

    hr {
        border-top: 1px solid var(--backgrounds-lines-n-100);
        margin-top: 16px;
        margin-bottom: 8px;
    }

    .BO-card-body {
        padding: 0px 16px;
        color: var(--text-icons-gray-300);
    }

    .card-title {
        font-size: 0.875rem;
        color: var(--text-icons-gray-100);
        text-transform: uppercase;
        margin: 0;
        font-weight: 500;
    }

    .card-title2 {
        color: var(--text-icons-gray-200);
        margin: 0;

        /* Overline */
        font-family: Roboto;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: uppercase;
    }

    .see-more a {
        color: var(--text-icons-gray-300, #4f5b6d);
        font-family: Roboto;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.21px;
        text-decoration-line: underline;
        text-transform: none;
    }

    .see-more a:hover {
        color: var(--text-icons-gray-100);
    }
`;
/* #endregion */

/**
 * This is a reusable component that will contain cards.
 *
 * @component
 * @param {Object} props - The properties for the CardComponentContainer.
 * @param {string} props.className - The CSS class name for the component.
 * @param {string} props.title - The title of the card.
 * @param {string} props.title2 - The title of the card.
 * @param {boolean} props.loading - The loading state of the card.
 * @param {ReactNode} props.children - The content of the card body.
 * @param {string} props.spacing - The spacing of the card.
 * @param {Object} props.seeMore - The see more link.
 * @param {string} props.seeMore.to - The see more URL.
 * @param {string} props.seeMore.text - The see more text.
 * @param {boolean} props.seeMore.visible - The see more visibility.
 * @param {boolean} props.rightComponent - The right component to show.
 * @returns {JSX.Element} The CardComponentContainer component.
 */
export default function CardComponentContainer(props) {
    const containerSpacing = props?.spacing || "8px";

    return (
        <StyledPage className={`${props?.className || ""}`}>
            <div className="BO-card-component">
                <div className="BO-card-header flex flex-row gap-8px justify-space-between">
                    <div className="flex flex-1 gap-8px">
                        <span className="card-title">{props?.title || "--"}</span>
                        {props?.title2 && <span className="card-title2">{props?.title2 || ""}</span>}
                        <LoadingSpinner loading={props?.loading} />
                    </div>
                    {props?.seeMore?.visible && (
                        <div className="see-more">
                            <Link to={props?.seeMore?.to}>{props?.seeMore?.text || "See more"}</Link>
                        </div>
                    )}
                    {props?.rightComponent && (
                        <div className="right-component flex">
                            {props?.rightComponent && <Fragment>{props.rightComponent}</Fragment>}
                        </div>
                    )}
                </div>
                <hr style={{ marginBottom: containerSpacing }} />

                <div className="BO-card-body">{props?.children && <Fragment>{props.children}</Fragment>}</div>
            </div>
        </StyledPage>
    );
}

const LoadingSpinner = props => {
    return (
        <div className={`loaderclass ${props?.loading === true ? "" : "hidden"} `}>
            <i className="fa fa-spinner fa-spin"></i>
        </div>
    );
};
