import React from "react";
import styled from "styled-components";
import { ReactComponent as Hand } from "../../../../assets/icons/hand.svg";
import { ReactComponent as Pencil01 } from "../../../../assets/icons/pencil-01.svg";
import { ReactComponent as Trash01 } from "../../../../assets/icons/trash-01.svg";
import { ReactComponent as RefreshCCW01 } from "../../../../assets/icons/refresh-ccw-01.svg";
import noImage from "../../../../assets/images/common/no-image-placeholder.png";
import imageLoadingErr from "../../../../assets/images/imageLoadingErr.jpg";

import BtnLargeSolidInverted02 from "../../buttons/BtnLargeSolidInverted02";
import { Tooltip } from "antd";
import ImageLoadingUrl from "../../ImageLoadingUrl";

/* #region  SCOPED CSS */
const StyledPage = styled.div`
    /* #region IMPORTED FIGMA CSS */
    #UIcard-style-03-m {
        display: flex;
        padding: 8px;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        flex: 1 0 0;
        border-radius: 4px;
        background: var(--backgrounds-lines-n-400);
    }

    #Frame-340 {
        display: flex;
        align-items: flex-start;
        align-self: stretch;
    }

    #Image-5 {
        height: 174px;
        flex: 1 0 0;
        border-radius: 4px;
        background: #141922;
        object-fit: cover;

        //Not in figma:
        width: 100%;
        object-fit: contain;
    }

    .Frame341 {
        display: flex;
        align-items: flex-start;
        gap: 8px;
        align-self: stretch;
        width: 100%;
    }

    .Frame343 {
        display: flex;
        align-items: flex-start;
        gap: 8px;
        align-self: stretch;
        border-radius: 4px;
        width: 100%;
    }

    .Frame342 {
        display: flex;
        align-items: flex-start;
        background: var(--backgrounds-lines-n-500);
        gap: 8px;
        align-self: stretch;
        width: 174px;
        height: 174px;
        flex-shrink: 0;
        padding: 20px;
    }

    #Frame-160 {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;
    }

    #Frame-172 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        justify-content: space-between;
        //gap: 8px;
        align-self: stretch;
        flex-shrink: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
    }

    #Frame-164 {
        display: flex;
        align-items: flex-start;
        gap: 8px;
        align-self: stretch;
    }

    #Frame-161 {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        align-self: stretch;
    }

    #CardTitle {
        color: var(--text-icons-gray-300);

        /* Label/Small - Uppercase */
        font-family: Roboto;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 500;
        letter-spacing: 0.125px;
        text-transform: uppercase;
        display: contents;
    }

    #Frame-162 {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 8px;
        flex: 1 0 0;
        margin-left: 3px;
    }

    .RightImageContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 8px;
        align-self: stretch;
        max-width: 100%;
    }

    .RightImage {
        display: flex;
        width: 40px;
        height: 40px;
        flex-shrink: 0;
        border-radius: 4px;
    }

    #BOTagID {
        align-self: stretch;
        color: var(--text-icons-gray-300);
        /* Label/Small - Uppercase */
        font-family: Roboto;
        font-size: 0.625rem;
        font-style: normal;
        font-weight: 500;
        letter-spacing: 0.125px;
        text-transform: uppercase;
    }

    #Frame-171 {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;
    }

    #Frame-144 {
        display: flex;
        align-items: center;
        gap: 10px;
        align-self: stretch;
    }

    #Details {
        color: var(--text-icons-gray-300);
    }

    #Line-1 {
        width: 337px; //TODO: fix this hardcoded width in figma
        flex-grow: 1; //TODO: add this to figma
        height: 1px;
        background: #3c485a;
    }

    .Frame-204 {
        display: flex;
        align-items: flex-start;
        gap: 24px;
        align-self: stretch;
    }

    .details-row-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        flex: 1 0 0;
        overflow: hidden;
    }

    .details-item {
        display: flex;
        align-items: center;
        gap: 8px;
        align-self: stretch;
    }

    .item-left {
        flex: 1 0 0;
        color: var(--text-icons-gray-300);
    }

    .item-right {
        color: var(--text-icons-gray-300);
        text-align: right;
        flex-shrink: 1;
    }

    /* #endregion */

    /* #region CUSTOM CSS */
    .Subtitle-3 {
        /* Subtitle 3 */
        font-family: Roboto;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .LabelXS-Uppercase {
        color: var(--text-icons-gray-300);

        /* Label/XS - Uppercase */
        font-family: Roboto;
        font-size: 0.625rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.1px;
        text-transform: uppercase;
    }

    &.card-borders {
        border-radius: 4px;
        border: 1px solid var(--backgrounds-lines-n-100);
    }

    .title-logo-container {
        border-radius: 4px;
        background: var(--backgrounds-lines-n-500);

        display: flex;
        height: 174px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        width: 100%;
    }

    .title-logo {
        color: var(--text-icons-blue-500);
        text-align: center;
        font-family: Roboto;
        font-size: 2.5rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.0375rem;
    }

    .border-suspended {
        border: 1px solid var(--text-icons-orange-500);
    }
    /* #endregion */
`;
/* #endregion */

/**
 * Renders a grid card component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.className - The class name for the component.
 * @param {boolean} props.isProfileCard - Indicates if the card is a profile card.
 * @param {string} props.profileImage - The profile image source for the card.
 * @param {string} props.profileImageFit - The object fit style for the profile image.
 * @param {boolean} props.useTitleLogo - Indicates if the title should be used instead of the image and uses the value.
 * @param {string} props.image - The image source for the card.
 * @param {string} props.imageFit - The object fit style for the image.
 * @param {boolean} props.imageHasPadding - Indicates if the image has padding.
 * @param {boolean} props.imageOnClick - The on click event for the image.
 * @param {boolean} props.hideImage - Indicates if the image is hidden.
 * @param {boolean} props.isImageWithOverlay - Indicates if the image has a title overlay.
 * @param {string} props.imageOverlay - The title overlay for the image.
 * @param {string} props.title - The title of the card.
 * @param {string} props.posLogo - The title logo of the card.
 * @param {string} props.category - The category of the card.
 * @param {Object} props.edit - Edit button object. Contains the active boolean property
 * @param {boolean} props.edit.hidden - Indicates if the edit button is hidden.
 * @param {Function} props.edit.onClick - The on click event for the edit button. *
 * @param {Object} props.suspend - Suspend button object. Contains the active boolean property
 * @param {boolean} props.suspend.hidden - Indicates if the suspend button is hidden.
 * @param {Function} props.suspend.onClick - The on click event for the suspend button.
 * @param {Object} props.delete - Delete button object. Contains the active boolean property
 * @param {boolean} props.delete.hidden - Indicates if the delete button is hidden.
 * @param {Function} props.delete.onClick - The on click event for the delete button.
 * @param {string} props.hasBorders - Indicates if the card has borders.
 * @param {string} props.botagID - The botag ID of the card.
 * @param {string} props.description - The description of the card.
 * @param {string} props.detailsType - The type of details to be displayed.
 * @param {Array} props.details - The details of the card.
 * @param {string} props.idPrefix - The ID prefix of the card.
 * @param {string} props.id - The ID of the card.
 * @param {string} props.titlePrefix - The title prefix of the card.
 * @param {boolean} props.isCardEnabled - Indicates if the card is enabled.
 * @param {boolean} props.isCardSuspended - Indicates if the card is suspended.
 * @param {Object} props.primaryButton - The primary button object.
 * @param {string} props.primaryButton.title - The title of the primary button.
 * @param {function} props.primaryButton.onClick - The on click event for the primary button.
 * @param {Object} props.secondaryButton - The secondary button object.
 * @param {string} props.secondaryButton.title - The title of the secondary button.
 * @param {function} props.secondaryButton.onClick - The on click event for the secondary button.
 * @param {Object} props.cardData - The card data object.
 * @returns {JSX.Element} The rendered grid card component.
 */
export default function GridCardComponent(props) {
    return (
        <StyledPage className={`${props?.className || ""} ${props?.hasBorders ? "card-borders" : ""}`}>
            <div id="UIcard-style-03-m" className={`${props?.isCardSuspended ? "border-suspended" : ""}`}>
                {!!props?.useTitleLogo
                    ? defaultTitleLogoSection(props)
                    : props?.isProfileCard
                    ? profileImageSection(props)
                    : props?.isImageWithOverlay
                    ? imageWithTitleOverlaySection(props)
                    : defaultImageSection(props)}

                <div id="Frame-160" style={{ overflow: "hidden" }}>
                    <div className="RightImageContainer">
                        <div id="Frame-172">
                            <div id="Frame-161" style={{ height: "16px" }}>
                                <div id="CardTitle" className="flex">
                                    <label className={`flex-shrink-0 ${props?.isCardEnabled ? "text-gray-100" : ""}`}>
                                        {props?.titlePrefix?.replace(":", "") || "--"}
                                    </label>
                                    :&nbsp;
                                    <label className={`text-one-line ${props?.isCardEnabled ? "text-blue-500" : ""}`}>
                                        {props?.title || "--"}
                                    </label>
                                </div>
                                <div id="Frame-162">
                                    <Pencil01
                                        onClick={props?.edit?.onClick}
                                        width="16px"
                                        height="16px"
                                        className={`${
                                            props?.edit?.onClick || props?.edit?.active
                                                ? "text-green-500 cursor-pointer"
                                                : "text-inactive hover-not-allowed"
                                        } ${props?.edit?.hidden ? "hidden" : ""}`}
                                    />
                                    {props?.suspend?.isReenable !== true ? (
                                        <Tooltip
                                            title="Suspend"
                                            trigger="hover"
                                            defaultOpen={false}
                                            overlayClassName="custom-tooltip">
                                            <Hand
                                                onClick={props?.suspend?.onClick}
                                                width="16px"
                                                height="16px"
                                                className={`${
                                                    props?.suspend?.onClick || props?.suspend?.active
                                                        ? "text-orange-500 cursor-pointer"
                                                        : "text-inactive hover-not-allowed"
                                                } ${props?.suspend?.hidden ? "hidden" : ""}`}
                                            />
                                        </Tooltip>
                                    ) : (
                                        <RefreshCCW01
                                            onClick={props?.suspend?.onClick}
                                            width="16px"
                                            height="16px"
                                            className={`${
                                                props?.suspend?.onClick || props?.suspend?.active
                                                    ? "text-blue-500 cursor-pointer"
                                                    : "text-inactive hover-not-allowed"
                                            } ${props?.suspend?.hidden ? "hidden" : ""}`}
                                        />
                                    )}
                                    <Tooltip
                                        title="Remove"
                                        trigger="hover"
                                        defaultOpen={false}
                                        overlayClassName="custom-tooltip">
                                        <Trash01
                                            onClick={props?.delete?.onClick}
                                            width="16px"
                                            height="16px"
                                            className={`${
                                                props?.delete?.onClick || props?.delete?.active
                                                    ? "text-red-500 cursor-pointer"
                                                    : "text-inactive hover-not-allowed"
                                            } ${props?.delete?.hidden ? "hidden" : ""}`}
                                        />
                                    </Tooltip>
                                </div>
                            </div>
                            <label id="BOTagID" className="flex">
                                <label
                                    className={`flex-shrink-0 text-one-line ${
                                        props?.isCardEnabled ? "text-gray-100" : ""
                                    }`}>
                                    {props?.idPrefix?.replace(":", "") || "BOTAG ID"}
                                </label>
                                :&nbsp;
                                <label className={`text-one-line ${props?.isCardEnabled ? "text-blue-500" : ""}`}>
                                    {props?.id || "--"}
                                </label>
                            </label>
                        </div>
                        {props?.posLogo && <img className="RightImage" src={props?.posLogo} alt="POS logo" />}
                    </div>
                    <div id="Frame-171">
                        <div id="Frame-144">
                            <label className={`LabelXS-Uppercase  ${props?.isCardEnabled ? "text-gray-100" : ""}`}>
                                Details
                            </label>
                            <div id="Line-1"></div>
                        </div>

                        {(!props?.detailsType || props?.detailsType?.toLowerCase() === "default") &&
                            defaultDetailsSectionV2(props)}

                        {props?.detailsType?.toLowerCase() === "account-details" && defaultDetailsSectionV2(props)}
                    </div>
                    <div id="Frame-164">
                        {props?.primaryButton && (
                            <BtnLargeSolidInverted02
                                disabled={props?.primaryButton?.onClick === undefined}
                                text={props?.primaryButton?.title}
                                onClick={
                                    props?.primaryButton?.onClick
                                        ? () => props?.primaryButton?.onClick(props?.cardData)
                                        : undefined
                                }
                                style={{ flexGrow: "1" }}
                            />
                        )}
                        {props?.secondaryButton && (
                            <BtnLargeSolidInverted02
                                disabled={props?.secondaryButton?.onClick === undefined}
                                text={props?.secondaryButton?.title}
                                onClick={
                                    props?.secondaryButton?.onClick
                                        ? () => props?.secondaryButton?.onClick(props?.cardData)
                                        : undefined
                                }
                                style={{ flexGrow: "1" }}
                            />
                        )}
                    </div>
                </div>
            </div>
        </StyledPage>
    );
}

/* #region OTHER COMPONENTS */

const defaultImageSection = props => {
    return (
        <div
            id="Frame-340"
            style={{ overflow: "hidden" }}
            className={`${props?.hideImage ? "hidden" : ""} ${props?.imageOnClick ? "cursor-pointer" : ""}`}>
            <ImageLoadingUrl
                id="Image-5"
                src={props?.image}
                alt="Card"
                onClick={props?.imageOnClick}
                style={{
                    minWidth: "0px",
                    objectFit: props?.imageFit ? props.imageFit : props?.image ? "contain" : "Cover",
                    padding: props?.imageHasPadding ? "30px" : "0px"
                }}
            />
        </div>
    );
};

const imageWithTitleOverlaySection = props => {
    return (
        <div
            id="Frame-340"
            style={{ overflow: "hidden", position: "relative" }}
            className={`${props?.hideImage ? "hidden" : ""} ${props?.imageOnClick ? "cursor-pointer" : ""}`}>
            <ImageLoadingUrl
                id="Image-5"
                src={props?.image}
                alt="Card"
                onClick={props?.imageOnClick}
                style={{
                    minWidth: "0px",
                    objectFit: props?.imageFit ? props.imageFit : props?.image ? "contain" : "Cover",
                    padding: props?.imageHasPadding ? "30px" : "0px"
                }}
            />
            <div
                style={{
                    position: "absolute",
                    bottom: "0",
                    left: "0",
                    width: "100%",
                    height: "34px",
                    background: "rgba(20, 25, 34, 0.80)",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }}>
                <ImageLoadingUrl
                    src={props?.imageOverlay}
                    hideOnError={true}
                    hideOnNoImage={true}
                    alt="POS logo"
                    style={{
                        height: "34px",
                        objectFit: "contain",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                />
            </div>
        </div>
    );
};

/*const fetchImageFromUrl = async url => {
    try {
        if (!url) {
            console.log("No image");
            return noImage;
        }

        const response = await fetch(url);
        if (!response.ok) {
            throw new Error("Image loading error");
        }
        const blob = await response.blob();
        console.log("image", blob);
        return URL.createObjectURL(blob);
    } catch (error) {
        console.log("Error", url);
        return imageLoadingErr;
    }
};*/

const defaultTitleLogoSection = props => {
    return (
        <div
            id="Frame-340"
            style={{ overflow: "hidden" }}
            className={`${props?.hideImage ? "hidden" : ""} ${props?.imageOnClick ? "cursor-pointer" : ""}`}>
            <div className="title-logo-container" onClick={props?.imageOnClick}>
                <label
                    className={`title-logo ${
                        props?.useTitleLogo === "--" || props?.useTitleLogo === "-" || !props?.isCardEnabled
                            ? "text-gray-300"
                            : ""
                    }`}>
                    {props?.useTitleLogo}
                </label>
            </div>
        </div>
    );
};

const profileImageSection = props => {
    return (
        <div
            id="Frame-340"
            style={{ overflow: "hidden" }}
            className={`${props?.hideImage ? "hidden" : ""} ${props?.imageOnClick ? "cursor-pointer" : ""}`}>
            <div className="Frame341">
                <div className="Frame343">
                    <img
                        id="Image-5"
                        src={props?.image}
                        alt="Card"
                        onClick={props?.imageOnClick}
                        style={{
                            minWidth: "0px",
                            maxWidth: "100%",
                            objectFit: props?.imageFit ? props.imageFit : props?.image ? "contain" : "Cover",
                            padding: props?.imageHasPadding ? "30px" : "0px"
                        }}
                    />
                </div>
                <img
                    className="Frame342"
                    src={props?.profileImage || noImage}
                    alt="Profile"
                    style={{
                        borderRadius: "4px",
                        minWidth: "0px",
                        objectFit: "cover"
                        // padding: props?.imageHasPadding ? "30px" : "0px"
                    }}
                />
            </div>
        </div>
    );
};

/**
 * Renders a default details section.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Array} props.details - The details to be displayed.
 * @param {boolean} props.isCardEnabled - Indicates if the card is enabled.
 * @returns {JSX.Element} The rendered default details section.
 * */
const defaultDetailsSectionV2 = props => {
    return (
        <div className="Frame-204">
            {Array.from({ length: Math.ceil(props?.details?.length / 4) }).map((_, index) => (
                <div key={index} className={"details-row-container"}>
                    {props?.details?.slice(index * 4, (index + 1) * 4).map((detail, i) => (
                        <div key={i} className="details-item">
                            <label
                                style={{ flex: "1 0 0" }}
                                className={`item-left Subtitle-3 text-no-wrap ${
                                    props?.isCardEnabled ? "text-gray-100" : "text-inactive"
                                }`}>
                                {detail?.key}
                            </label>
                            <label
                                style={{ textAlign: "right" }}
                                className={`item-right Subtitle-3 text-one-line ${
                                    props?.isCardEnabled ? "text-blue-500" : "text-inactive"
                                }`}>
                                {detail?.value ?? "--"}
                            </label>
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
};

/* #endregion */
