import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";
import ChevronDown from "@untitled-ui/icons-react/build/esm/ChevronDown";
import ChevronRight from "@untitled-ui/icons-react/build/esm/ChevronRight";
import Edit02 from "@untitled-ui/icons-react/build/esm/Edit02";
import Settings03 from "@untitled-ui/icons-react/build/esm/Settings03";
import CreditCard02 from "@untitled-ui/icons-react/build/esm/CreditCard02";
import HelpCircle from "@untitled-ui/icons-react/build/esm/HelpCircle";

/* #region  SCOPED CSS */
const StyledPage = styled.div`
    // HARCODED HEIGHT FOR SHOWING BORDER WITH THE
    //CORRECT PADDING RIGHT TO CONSIDER THE SCROLL
    //HAS TO BE ADJUSTED WHEN MODIFYING NEW MENUS ITEMS
    //CURRENTLY FOR 10 MENU ITEMS
    @media (max-height: 922px) {
        .navitems-area {
            padding-right: 7px;
        }

        .navitems-area.bottom-section {
            width: 213px !important;
        }

        .navitems-area.bottom-section ul {
            width: 213px !important;
        }
    }

    .main-sidebar {
        background: var(--backgrounds-lines-n-300);
        width: 220px;
    }

    .id-logo {
        text-align: center;
        padding: 0;
    }

    .logo-name {
        font-family: Roboto;
        height: 76px;
        margin: 8px 8px 10px 8px;
        padding: 0 !important;
    }

    .logo-name > img {
        height: 76px;
        object-fit: contain;
        width: 100%;
    }

    .logo-name > .editComp {
        display: none;
    }

    .logo-name:hover > .editComp {
        display: block;
        z-index: 1555555;
        color: var(--text-icons-gray-100);
        background-color: #1d2530;
        border-color: #3b3c3d;
        width: 49px;
        position: absolute;
        top: 35px;
        left: 85px;
    }

    .id-name {
        font-size: 0.875rem;
        margin-bottom: 8px;
        text-transform: uppercase;
        text-align: center;
    }

    .id-name.description {
        font-size: 8px;
    }

    .id-details {
        margin: 8px;
        padding: 8px;
    }

    .id-details .active-company {
        display: flex;
        flex-direction: column;
        align-items: center;
        align-self: stretch;
        position: relative;
    }

    .id-details .active-company > .icon {
        position: absolute;
        right: 0;
        top: -2px;
    }

    .id-details .active-company > .icon svg {
        width: 12px;
        height: 12px;
        color: var(--text-icons-gray-100);
    }

    .id-details .active-company > hr {
        height: 1px;
        width: 100%;
        border-color: var(--backgrounds-lines-n-200);
        margin: 16px 0 12px 0;
    }

    .id-details ul {
        margin: 0;
        padding: 0;
    }

    .id-details ul li {
        color: var(--text-icons-gray-100);
        font-size: 12px;
        line-height: normal;
        list-style-type: none;
        margin: 0 0 8px 0;
    }

    .id-details ul li:last-child {
        margin-bottom: 0px;
    }

    .id-details ul li span {
        color: var(--text-icons-blue-500);
    }

    .id-details ul li span.orng {
        color: var(--text-icons-orange-500) !important;
    }

    .id-details .anchor-mini-button {
        float: right;
        color: var(--text-icons-gray-300);

        border-radius: 3px;
        position: relative;
        top: -2px;
        right: 2px;
        text-align: center;
        width: 20px;
    }

    .id-details .anchor-mini-button > i {
        margin: 3px;
    }

    .id-details .anchor-mini-button:hover {
        color: var(--text-icons-gray-100);
        border-color: var(--text-icons-gray-100);
    }

    .navigation-menu {
        background: var(--backgrounds-lines-n-400);
        display: block;
        height: 100%;
        //overflow-y: auto;
        margin-bottom: 63px;
    }

    .navitems-area {
        background: var(--backgrounds-lines-n-400);
        //float: left;
        width: 100%;
    }

    .navitems-area ul {
        padding: 0;
    }

    .navitems-area ul li {
        display: block;
        font-size: 12px;
        list-style-type: none;
        text-align: center;
    }

    .navitems-area ul li.disabled a {
        color: var(--text-icons-gray-300);
    }

    .navitems-area ul li a {
        border: 1px solid var(--backgrounds-lines-n-200);
        border-top-color: transparent;
        color: var(--text-icons-gray-100);
        display: block;
        //float: left;
        padding: 0px;
        position: relative;
        text-align: left;
        width: 100%;
    }

    .menu-images {
        line-height: 46px;
        width: 100%;
        display: flex;
    }

    .menu-img-bg {
        position: relative;
        padding: 0 8px 0 25px;
    }

    .navitems-area ul li .menu-images img,
    .navitems-area.bottom-section ul li .menu-images img {
        width: 20px;
    }

    .navitems-area.bottom-section .menu-images {
        line-height: 42px;
        justify-content: center;
    }

    .navitems-area.bottom-section a {
    }

    /*.navitems-area ul li.menu-item {
        clear: both;
        width: 100%;
    }*/

    .navitems-area ul li a.active,
    .navitems-area ul li a:active {
        border: 1px solid var(--App-Accent-Outline);
        position: relative;
        text-decoration: none;
    }

    .navitems-area ul li a:hover {
        border: 1px solid var(--App-Accent-Outline);
        text-decoration: none;
    }

    .navitems-area.bottom-section {
        border-right: 1px solid var(--backgrounds-lines-n-200);
        bottom: 38px;
        padding: 0;
        position: fixed;
        width: 220px;
    }

    .navitems-area.bottom-section ul {
        margin: 0;
        width: 220px;
    }

    .navitems-area.bottom-section ul li.bottom-items {
        height: 72px;
        width: 33.33%;
    }

    .navitems-area ul li.bottom-items {
        float: left;
        width: 50%;
    }

    .navitems-area.bottom-section ul li a {
        border: 1px solid var(--backgrounds-lines-n-200);
        font-size: 10px;
        padding: 16px 8px;
        text-align: center;
        height: 70px;
    }
`;
/* #endregion */

/**
 * Represents a styled div component for the navigation bar.
 * @param {object} props - The props of the component
 * @param {string} props.className - The class name of the component
 * @param {Array} props.menuBtns - The menu buttons
 * @param {string} props.menuBtns[].title - The title of the button
 * @param {string} props.menuBtns[].to - The link of the button
 * @param {string} props.menuBtns[].icon - The icon of the button
 * @param {string} props.menuBtns[].isActive - The active state of the button
 * @param {string} props.menuBtns[].enabled - The enabled state of the button
 * @param {string} props.menuBtns[].menuOnIcon - The "on" icon of the button
 * @param {string} props.menuBtns[].menuOffIcon - The "off" icon of the button
 * @returns {JSX.Element} - The rendered component
 */
function NavigationBar(props) {
    /* #region STATES */
    const navigate = useNavigate();

    useRef();
    const [organizationName, setOrganizationName] = useState(""); //TODO: setOrganizationName
    const [companyName, setCompanyName] = useState("");
    const [navigationBarLogo, setNavigationBarLogo] = useState("");
    const [status, setStatus] = useState("");
    const [billingPlan, setBillingPlan] = useState("");
    //const [activeSuppliers, setActiveSuppliers] = useState("");
    //const [activeMembers, setActiveMembers] = useState("");
    const [activeCompanies, setActiveCompanies] = useState("");
    const [activeBrands, setActiveBrands] = useState("");
    const [activeStores, setActiveStores] = useState("");
    const [activeFFCenters, setActiveFFCenters] = useState("");
    const [isRefresh, setIsRefresh] = useState(false);
    /* #endregion */

    /* #region EFFECTS */
    useEffect(() => {
        if (localStorage.getItem("currentCompanyID") || isRefresh) {
            setIsRefresh(false);
            setCompanyName(localStorage.getItem("currentCompanyName"));
            setOrganizationName(localStorage.getItem("currentOrganizationName"));
            setNavigationBarLogo(localStorage.getItem("currentOrganizationLogo"));
            setStatus(localStorage.getItem("status"));
            setBillingPlan(localStorage.getItem("billingPlan"));
            //setActiveSuppliers(localStorage.getItem("activeSuppliers"));
            //setActiveMembers(localStorage.getItem("activeMembers"));
            setActiveCompanies(localStorage.getItem("activeCompanies")); //TODO: activeCompanies
            setActiveBrands(localStorage.getItem("activeRetailBrands"));
            setActiveStores(localStorage.getItem("activeStores"));
            setActiveFFCenters(localStorage.getItem("activeFFCenters")); //TODO: activeFFCenters
        }
    }, [localStorage.getItem("currentCompanyID"), isRefresh]);

    useEffect(() => {
        const interval = setInterval(refreshNavigationBar, 3000);
        return () => clearInterval(interval);
    });
    /* #endregion */

    /* #region METHODS */
    const refreshNavigationBar = () => {
        const auth = localStorage.getItem("token");
        if (!auth) {
            navigate("/");
        } else {
            //setIsRefresh(true);
        }

        setCompanyName(localStorage.getItem("currentCompanyName"));
        setOrganizationName(localStorage.getItem("currentOrganizationName"));
        setNavigationBarLogo(localStorage.getItem("currentOrganizationLogo"));
        setStatus(localStorage.getItem("status"));
        setBillingPlan(localStorage.getItem("billingPlan"));
        //setActiveSuppliers(localStorage.getItem("activeSuppliers"));
        //setActiveMembers(localStorage.getItem("activeMembers"));
        setActiveCompanies(localStorage.getItem("activeCompanies")); //TODO: activeCompanies
        setActiveBrands(localStorage.getItem("activeRetailBrands"));
        setActiveStores(localStorage.getItem("activeStores"));
        setActiveFFCenters(localStorage.getItem("activeFFCenters"));
    };

    const logoClicked = () => {
        /*if (status >= 10 || status === 'Completed') {
            navigate("/account/company/logo");
        }*/
    };
    /* #endregion */

    return (
        <StyledPage className={`${props?.className || ""}`}>
            <aside className="main-sidebar">
                <div className="id-area">
                    <div className={navigationBarLogo ? "id-logo" : "id-logo no-logo"}>
                        <div className={navigationBarLogo ? "logo-name logoimg" : "logo-name"} onClick={null}>
                            {navigationBarLogo ? (
                                <>
                                    <img
                                        alt=""
                                        className="leftmainlogo"
                                        src={navigationBarLogo}
                                        style={{ paddingLeft: "16px", paddingRight: "16px" }}
                                    />
                                    {status === 100 || status === "Completed" ? (
                                        <span className="btn btn-success editComp saveIt" onClick={logoClicked}>
                                            <i className="fa-thin fa-edit saveIt"></i>
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </>
                            ) : (
                                <>
                                    <span className="maincompany">Company Logo</span>
                                    <small>Max 200x90px 100kb</small>
                                </>
                            )}
                        </div>
                        <div className="id-name description text-gray-200" style={{ marginBottom: "3px" }}>
                            Account Name
                        </div>
                        <div className="id-name text-blue-500">{organizationName || "- Organization -"}</div>
                    </div>
                    <div className="id-details n-600 border-radius">
                        <div className="active-company">
                            <div className="icon hidden">
                                <ChevronDown />
                            </div>
                            <div className="id-name description text-gray-100">Active Company</div>
                            <div className="id-name text-blue-500 m-0">{companyName}</div>
                            <hr />
                        </div>
                        <ul>
                            <li>
                                Current Status: <span className="text-blue-500 pl-5px">Active</span>
                                {/* <span className={status === "Completed" ? "text-blue-500 pl-5px" : "text-orange-500pl-5px"}>
                                    {status === "Completed" ? "Active" : status}
                                </span> */}
                            </li>
                            <li>
                                Billing Plan:
                                <span className={`${billingPlan ? "text-blue-500" : "text-orange-500"} pl-5px`}>
                                    {billingPlan || "-"}
                                </span>
                                <Link to="/#" onClick={e => e.preventDefault()}>
                                    <span className="anchor-mini-button">
                                        <Edit02
                                            className={"text-default text-inactive hover-not-allowed"}
                                            height="13px"
                                        />
                                    </span>
                                </Link>
                            </li>

                            <li>
                                Active Companies:
                                <span className="text-blue-500 pl-5px">{activeCompanies || "-"}</span>
                                <Link to="/companies" className="">
                                    <span className="anchor-mini-button">
                                        <ChevronRight className="text-default" height="13px" />
                                    </span>
                                </Link>
                            </li>
                            <li>
                                Active Brands:
                                <span className="text-blue-500 pl-5px">{activeBrands || "-"}</span>
                                <Link to="/brands">
                                    <span className="anchor-mini-button">
                                        <ChevronRight className="text-default" height="13px" />
                                    </span>
                                </Link>
                            </li>
                            <li>
                                Active Stores:
                                <span className="text-blue-500 pl-5px">{activeStores || "-"}</span>
                                <Link to="/stores">
                                    <span className="anchor-mini-button">
                                        <ChevronRight className="text-default" height="13px" />
                                    </span>
                                </Link>
                            </li>
                            <li>
                                Active FFCenters:
                                <span className="text-blue-500 pl-5px">{activeFFCenters || "-"}</span>
                                <Link to="/ffcenters">
                                    <span className="anchor-mini-button">
                                        <ChevronRight className="text-default" height="13px" />
                                    </span>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="navigation-menu">
                    <div className="navitems-area">
                        <ul>
                            {props?.menuBtns?.map(btn => {
                                const key = uuidv4();
                                return (
                                    <li
                                        key={key}
                                        className={`menu-item ${btn?.enabled ? "" : "disabled"} ${
                                            btn?.isActive ? "active" : ""
                                        }`}>
                                        <Link
                                            to={btn?.enabled ? btn?.to : "#"}
                                            onClick={btn?.onClick}
                                            className={`${btn?.isActive ? "active" : ""} ${
                                                btn?.enabled ? "" : "hover-not-allowed"
                                            }`}>
                                            <div className="menu-images">
                                                <div className="menu-img-bg">
                                                    {btn?.icon && (
                                                        <span className={btn?.enabled ? "menu-enabled" : "disabled"}>
                                                            <i className={` fa-xl ${btn?.icon}`} aria-hidden="true"></i>
                                                        </span>
                                                    )}

                                                    {btn?.menuOnIcon && (
                                                        <img
                                                            alt={btn?.title}
                                                            className={btn?.enabled ? "menu-enabled" : "disabled"}
                                                            src={
                                                                btn?.enabled
                                                                    ? btn?.menuOnIcon.icon
                                                                    : btn?.menuOffIcon.icon
                                                            }
                                                        />
                                                    )}

                                                    {btn?.iconSVG && (
                                                        <btn.iconSVG
                                                            alt={btn?.title}
                                                            height="100%"
                                                            width="16px"
                                                            className={
                                                                btn?.enabled
                                                                    ? "menu-enabled text-default"
                                                                    : "disabled text-inactive"
                                                            }
                                                        />
                                                    )}
                                                </div>

                                                <span className="text-one-line" style={{ marginRight: "25px" }}>
                                                    {btn?.title}
                                                </span>
                                            </div>
                                        </Link>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </div>

                <div className="navitems-area bottom-section">
                    <ul>
                        <li className="bottom-items disabled">
                            <Link to="#" className="hover-not-allowed" onClick={e => e.preventDefault()}>
                                <div className="menu-images mb-8px">
                                    <Settings03 className="disabled text-inactive" height="100%" width="16px" />
                                </div>
                                <span>Settings</span>
                            </Link>
                        </li>

                        <li className="bottom-items disabled">
                            <Link to="#" className="hover-not-allowed" onClick={e => e.preventDefault()}>
                                <div className="menu-images mb-8px">
                                    <CreditCard02 className="disabled text-inactive" height="100%" width="16px" />
                                </div>
                                <span>Billing</span>
                            </Link>
                        </li>

                        <li className="bottom-items disabled">
                            <Link to="#" className="hover-not-allowed" onClick={e => e.preventDefault()}>
                                <div className="menu-images mb-8px">
                                    <HelpCircle className="disabled text-inactive" height="100%" width="16px" />
                                </div>
                                <span>Support</span>
                            </Link>
                        </li>
                    </ul>
                </div>
            </aside>
        </StyledPage>
    );
}

export default NavigationBar;
