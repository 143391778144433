/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import "../../../assets/css/pages/orderPreview.css";
import * as Library from "../../../utils/Library";
import moment from "moment";
import { TableComponent } from "../../common/lists/old/TableComponent";
import FullScreenLoader from "../../common/loaders/FullScreenLoader";

export default function OrderPreview({ onCloseClick, orderDetails }) {
    const [orderList, setOrderList] = useState([]);
    const [preLoader, setPreLoader] = useState(false);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(50);
    const [dataLoading, setDataLoading] = useState(false);
    const [orderInfo, setOrderInfo] = useState({});
    const [totalCount, setTotalCount] = useState();
    const CustomLoader = () => (
        <div className="loaderclass">
            <i className="fa fa-spinner fa-spin"></i>
        </div>
    );
    const Checkbox = React.forwardRef(({ onClick, ...rest }, ref) => {
        return (
            <>
                <label className="container-checkbox" style={{ display: "none" }}>
                    <input type="checkbox" ref={ref} onClick={onClick} {...rest} />
                    <span className="checkmark"></span>
                </label>
            </>
        );
    });

    const fetchData = (page, perPage) => {
        const formData = {
            LabelBrandOrderID: orderDetails?.labelBrandOrderID,
            page: page,
            limit: perPage
        };
        if (orderDetails) {
            setDataLoading(true);
            Library.makePostRequest("orderPreviewPage", formData, false, setPreLoader).then(res => {
                if (res.data.status !== 200) return;

                const data = res.data?.records?.aggregationResult;
                const orderInfo = res.data?.records?.orderDetails;

                setTotalCount(res.data?.totalCount);
                setOrderInfo(orderInfo);

                setOrderList(data);
                setDataLoading(false);
            });
        }
    };
    useEffect(() => {
        setOrderList([]);
        fetchData(page, perPage);
    }, [page, perPage]);

    const handlePageChange = e => {
        setPage(e);
    };
    const handleRowsPerPageChange = (newRowsPerPage, currentPage) => {
        setPage(1);
        setPerPage(newRowsPerPage);
    };
    const columns = [
        {
            name: "EAN code",
            selector: row => (row.EANcode ? row.EANcode : "-"),
            sortable: true,
            width: "130px"
        },
        {
            name: "Style name",
            selector: row => (row.StyleName ? row.StyleName : "-"),
            width: "125px"
        },
        {
            name: "style nr",
            selector: row => (row.Refrence ? row.stylenr : "-"),
            width: "150px"
        },
        {
            name: "Reference",
            selector: row => (row.Title ? row.Reference : "-"),
            style: {
                minWidth: "150px"
            }
        },
        {
            name: "SKU",
            selector: row => (row?.Description ? row.SKU : "-"),
            width: "110px"
        },
        {
            name: "Short description",
            selector: row => (row?.CountryOfOrigin ? row.Shortdescription : "-"),
            width: "130px"
        },
        {
            name: "Size",
            selector: row => (row.Composition ? row.Size : "-"),
            width: "130px"
        },
        {
            name: "Color",
            selector: row => (row.Maintenance ? row.Color : "-"),
            width: "130px"
        },
        {
            name: "Color Description",
            selector: row => (row.Size ? row.ColorDescription : "-"),
            width: "130px"
        },
        {
            name: "Color code",
            selector: row => (row.ColorName ? row.Colorcode : "-"),
            width: "130px"
        },
        {
            name: "Quantity",
            selector: row =>
                row.Price ? (
                    <div
                        style={{
                            width: "17px",
                            height: "17px",
                            background: `#${row?.Quantity}`,
                            border: "1px solid #d1d1d1",
                            marginLeft: "3px"
                        }}
                        title={row?.ColorCode}></div>
                ) : (
                    "-"
                ),
            width: "130px",
            style: {
                display: "flex",
                alignItems: "center"
            }
        },
        {
            name: "Price (excal.VAT)",
            selector: row => (row.Quantity ? row.Price : "-"),
            width: "130px",
            style: { position: "" }
        },
        {
            name: "",
            width: "0px"
        }
    ];

    const customStyles = {
        headCells: {
            style: {
                "&:nth-child(2)": {
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                    paddingLeft: "0px !important"
                },
                "&:nth-child(1)": {
                    flex: "0 0 0 !important"
                }
            }
        },
        cells: {
            style: {
                "&:nth-child(1)": {
                    flex: "0 0 0 !important",
                    minWidth: "0px !important"
                }
            }
        },
        rows: {
            style: {
                "minHeight": "40px",
                "margin": "8px 0px",
                "borderRadius": "4px",

                "border": "none !important",
                "&:nth-child(odd)": {
                    backgroundColor: "#1A222D"
                },
                "&:hover": {
                    backgroundColor: "#141922"
                }
            }
        }
    };

    return (
        <>
            <FullScreenLoader isLoading={dataLoading || preLoader} isLoadingOverlay={true}>
                <div className="order-preview-container">
                    <div className="order-preview-header">
                        <div className="order-preview-heading">ORDERS FILE PREVIEW</div>
                        <div className="order-preview-cross" onClick={onCloseClick}>
                            <i className="fa-solid fa-xmark"></i>
                        </div>
                    </div>

                    <div className="supplier-info">
                        <div>
                            <div className="supplier-heading">FROM</div>
                            <div className="supplier-key-value">
                                <div>
                                    Supplier:<span> {orderDetails?.supplier}</span>
                                </div>
                                <div>
                                    Brand: <span> {orderDetails?.brandName}</span>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="supplier-heading">TO</div>
                            <div className="key-value-container">
                                <div className="supplier-key-value">
                                    <div>
                                        Customer:<span> {orderInfo?.customer || "-"}</span>
                                    </div>
                                    <div>
                                        Store name:<span>{orderDetails?.store}</span>
                                    </div>
                                </div>
                                <div className="supplier-key-value">
                                    <div>
                                        Brand's Customer nr: <span> {orderInfo?.customerNR || "-"}</span>
                                    </div>
                                    <div>
                                        Texcon member: <span>-</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="dropdown-key-val">
                            <div className="dropdown">
                                <button
                                    className="download-btn btn-outline-none
                            dropdown-toggle bg-black order-preview-drop-down"
                                    type="button"
                                    id="dropdownMenuButton"
                                    data-toggle="dropdown"
                                    disabled
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        gap: "0.7rem",
                                        backgroundColor: "#1e2631",
                                        border: "none",
                                        color: "var(--text-icons-gray-300)",
                                        cursor: "not-allowed"
                                    }}>
                                    <i class="fa-regular fa-arrow-down-to-line" style={{ fontSize: "10px" }}></i>
                                    <div style={{ fontSize: "10px" }}>EXPORT AS</div>
                                </button>
                                <div className="dropdown-menu " aria-labelledby="dropdownMenuButton">
                                    <a className="dropdown-item" href="#">
                                        CSV
                                    </a>
                                    <br />
                                    <a className="dropdown-item" href="#">
                                        Excel
                                    </a>
                                    <br />
                                    <a className="dropdown-item" href="#">
                                        PDF
                                    </a>
                                    <br />
                                    <a className="dropdown-item" href="#">
                                        Print
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="supplier-info">
                        <div>
                            <div className="supplier-heading">ORDER ID</div>
                            <div className="supplier-key-value">
                                <div>
                                    Order nr: <span> {orderInfo?.orderNr || "-"}</span>
                                </div>
                                <div>
                                    Order ID: <span> {orderInfo?.orderID || "-"}</span>
                                </div>
                                <div>
                                    Order Type: <span> {orderInfo?.orderType || "-"}</span>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="supplier-heading">ORDER TIMELINE</div>
                            <div className="supplier-key-value">
                                <div>
                                    Order date:{" "}
                                    <span> {moment(orderInfo?.orderDate).format("MM/DD/YYYY HH:mm") || "-"}</span>
                                </div>
                                <div>
                                    Delivery window: <span>{orderInfo?.deliveryWindow || "-"}</span>
                                </div>
                                <div>
                                    Estimated delivery date:{" "}
                                    <span>{orderInfo?.estimatedDeliveryDate?.split("T")[0] || "-"}</span>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="supplier-heading">COLLECTION INFO</div>
                            <div className="supplier-key-value">
                                <div>
                                    Collection name: <span>{orderInfo?.collectionName || "-"}</span>
                                </div>
                                <div>
                                    Collection code: <span>{orderInfo?.collectionCode || "-"}</span>
                                </div>
                                <div>
                                    Season: <span>{orderDetails?.season}</span>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="supplier-heading">COLLECTION TOTALS</div>
                            <div className="key-value-container">
                                <div className="supplier-key-value">
                                    <div>
                                        Total items: <span>{orderInfo?.totalItems || "-"}</span>
                                    </div>
                                    <div>
                                        VAT%: <span>{orderInfo?.VATpercentage || "-"}</span>
                                    </div>
                                    <div>
                                        VAT total: <span>{orderInfo?.totalVAT || "-"}</span>
                                    </div>
                                </div>
                                <div className="supplier-key-value">
                                    <div>
                                        Currency: <span>{orderInfo?.currency || "-"}</span>
                                    </div>
                                    <div>
                                        Total excl VAT: <span>{orderInfo?.totalPriceExcludingVAT || "-"}</span>
                                    </div>
                                    <div>
                                        Total incl. VAT: <span>{orderInfo?.totalPriceIncludingVAT || "-"}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="tablestructure sticky-action" style={{ padding: "0px 1px" }}>
                        {/* <div className="row">
          <div className="col-md-12">
            <h4>Orders List</h4>
          </div>
        </div> */}

                        <div className="row">
                            <div className="col-md-12">
                                <TableComponent
                                    columns={columns}
                                    data={orderList}
                                    theme="solarized"
                                    // sortIcon={<i className="fa fa-sort-amount-asc" aria-hidden="true"></i>}
                                    progressPending={preLoader || dataLoading}
                                    selectableRows
                                    selectableRowsComponent={Checkbox}
                                    defaultSortFieldId={1}
                                    defaultSortAsc={true}
                                    responsive={true}
                                    //   expandableRows={true}
                                    //   expandableRowsComponent={ExpandedComponent}
                                    progressComponent={<CustomLoader />}
                                    //actions={actionsMemo}
                                    paginationServer
                                    pagination
                                    paginationRowsPerPageOptions={[50, 75, 100]}
                                    onChangeRowsPerPage={handleRowsPerPageChange}
                                    paginationPerPage={perPage}
                                    onChangePage={handlePageChange}
                                    paginationTotalRows={totalCount}
                                    paginationDefaultPage={page}
                                    customStyles={customStyles}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </FullScreenLoader>
        </>
    );
}
